/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type PredictionProductType =
  (typeof PredictionProductType)[keyof typeof PredictionProductType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PredictionProductType = {
  "manual-upload": "manual-upload",
  automated: "automated",
} as const;
