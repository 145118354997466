import { useLayoutEffect, useRef } from "react";

function useUpdatingRef<T>(value: T): React.MutableRefObject<T> {
  const ref = useRef(value);

  useLayoutEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

export default useUpdatingRef;
