import type { LngLatLike } from "mapbox-gl";

export type InteractiveMapElement = "feature" | "marker";

export type MapInteractionType = "popup" | "persistent";

export enum InteractionStateType {
  CLICKED = "clicked",
  HOVERED = "hovered",
}

export interface FeatureInteractionProperties {
  featureId: string | number;
  lngLat: LngLatLike;
}

export type InteractionFeature = {
  layerId: string;
  properties: FeatureInteractionProperties;
};

type ActiveFeatureInteractionState<P extends FeatureInteractionProperties> = {
  allFeatures: InteractionFeature[];
  isActive: true;
  layerId: string;
  properties: P;
};

type InactiveFeatureInteractionState<P extends FeatureInteractionProperties> = {
  allFeatures: InteractionFeature[] | null;
  isActive: false;
  layerId: string | null;
  properties: P | null;
};

export type FeatureInteractionState<P extends FeatureInteractionProperties> = {
  id: string | number;
} & (ActiveFeatureInteractionState<P> | InactiveFeatureInteractionState<P>);
