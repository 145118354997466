import ColorRangeLegend, {
  type ColorRange,
  type ColorRangeCollection,
} from "../ColorRangeLegend/ColorRangeLegend";

const flameHeightRanges: ColorRange[] = [
  { color: "#019C7B", range: "Low" },
  { color: "#FFF354", range: "Medium" },
  { color: "#FAB033", range: "High" },
  { color: "#ED3422", range: "Very High" },
  { color: "#000000", range: "Extreme" },
];

const ranges: ColorRangeCollection[] = [
  { label: "Average flame height", ranges: flameHeightRanges },
];

const FlameHeightLegend = () => {
  return <ColorRangeLegend ranges={ranges} />;
};

export default FlameHeightLegend;
