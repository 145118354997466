import type { Feature } from "geojson";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type { MapboxMouseEvent } from "../types";
import type {
  FeatureInteractionProperties,
  InteractiveMapElement,
} from "./types";
import useMapInteractionEvents from "./useMapInteractionEvents";
import useMapInteractionState, {
  type MapInteractionState,
} from "./useMapInteractionState";

export type GetPropertiesFromFeatureFn<P extends FeatureInteractionProperties> =
  (
    feature: Feature,
    event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
  ) => P | null;

export interface InteractiveLayer<
  P extends FeatureInteractionProperties = FeatureInteractionProperties,
> {
  element: InteractiveMapElement;
  getPropertiesFromFeature: GetPropertiesFromFeatureFn<P>;
  layerId: string;
  onClick?: (properties: P) => void;
  sourceId?: string;
}

export type InteractiveLayerMap = Map<string, InteractiveLayer>;

interface RegisterMapInteractionParams<P extends FeatureInteractionProperties> {
  layer: InteractiveLayer<P>;
}

interface UnregisterMapInteractionParams {
  layerId: string;
}

type RegisterMapInteractionFn<P extends FeatureInteractionProperties> = (
  params: RegisterMapInteractionParams<P>,
) => void;
type UnregisterMapInteractionFn = (
  params: UnregisterMapInteractionParams,
) => void;

interface MapInteractionsContextValue<
  P extends FeatureInteractionProperties = FeatureInteractionProperties,
> {
  interactionState: MapInteractionState<FeatureInteractionProperties>;
  register: RegisterMapInteractionFn<P>;
  unregister: UnregisterMapInteractionFn;
}

export const MapInteractionsContext = createContext<
  MapInteractionsContextValue | undefined
>(undefined);

interface MapInteractionsProviderProps {
  children?: React.ReactNode;
}

const MapInteractionsProvider = ({
  children,
}: MapInteractionsProviderProps) => {
  const [interactiveLayers, setInteractiveLayers] =
    useState<InteractiveLayerMap>(() => new Map());

  const register = useCallback(
    <P extends FeatureInteractionProperties>({
      layer,
    }: RegisterMapInteractionParams<P>) => {
      setInteractiveLayers((prev) => {
        const map = new Map(prev);
        map.set(layer.layerId, layer as unknown as InteractiveLayer);
        return map;
      });
    },
    [],
  );

  const unregister: UnregisterMapInteractionFn = useCallback(({ layerId }) => {
    setInteractiveLayers((prev) => {
      const map = new Map([...prev]);
      map.delete(layerId);
      return map;
    });
  }, []);

  const interactionState =
    useMapInteractionState<FeatureInteractionProperties>();

  useMapInteractionEvents({
    layers: interactiveLayers,
    interactionState,
  });

  const value = useMemo<MapInteractionsContextValue>(
    () => ({
      interactionState,
      register,
      unregister,
    }),
    [interactionState, register, unregister],
  );

  return (
    <MapInteractionsContext.Provider value={value}>
      {children}
    </MapInteractionsContext.Provider>
  );
};

export default MapInteractionsProvider;

export const useMapInteractionsContext = <
  P extends FeatureInteractionProperties,
>() => {
  const context = useContext(MapInteractionsContext) as
    | MapInteractionsContextValue<P>
    | undefined;

  if (context === undefined) {
    throw new Error(
      "useMapInteractionContext must be used within a MapInteractionProvider",
    );
  }

  return context;
};
