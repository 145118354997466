/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { ManualIncidentPredictionProduct } from "./manualIncidentPredictionProduct";

export type PredictionProduct =
  (typeof PredictionProduct)[keyof typeof PredictionProduct];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PredictionProduct = {
  ...ManualIncidentPredictionProduct,
  "rapid-appreciation": "rapid-appreciation",
  "red-map": "red-map",
} as const;
