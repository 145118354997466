import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  type Dispatch,
} from "react";
import type { LayerSet } from "../../../config/layers/layers";
import type { Tool, Toolset } from "../../../config/tools";

interface SetToolAction {
  payload: Tool | null;
  type: "setActiveTool";
}

export type VisualiserAction = SetToolAction;

export interface VisualiserState {
  activeTool: Tool | null;
  toolsets: Toolset[];
  layerSets: LayerSet[];
}

const INITIAL_VISUALISER_STATE: VisualiserState = {
  activeTool: null,
  toolsets: [],
  layerSets: [],
};

export type VisualiserDispatch = Dispatch<VisualiserAction>;

export const visualiserReducer = (
  state: VisualiserState,
  { payload, type }: VisualiserAction,
): VisualiserState => {
  let nextState: VisualiserState;

  // Update state

  switch (type) {
    case "setActiveTool":
      nextState = {
        ...state,
        activeTool: payload,
      };
      break;
  }

  return nextState;
};

interface VisualiserContextValue {
  is3DEnabled: boolean;
  isToolActive: (tool: Tool) => boolean;
  onToolComplete: () => void;
  visualiserState: VisualiserState;
  visualiserDispatch: VisualiserDispatch;
}

const VisualiserContext = createContext<VisualiserContextValue | undefined>(
  undefined,
);

interface VisualiserProviderProps {
  children?: React.ReactNode;
  initialState?: Partial<VisualiserState>;
  is3DEnabled: boolean;
  toolsets?: Toolset[];
  layerSets?: LayerSet[];
}

const VisualiserProvider = ({
  children,
  initialState,
  is3DEnabled,
  toolsets,
  layerSets,
}: VisualiserProviderProps) => {
  const [visualiserState, visualiserDispatch] = useReducer(visualiserReducer, {
    ...INITIAL_VISUALISER_STATE,
    ...initialState,
    ...(toolsets && { toolsets }),
    ...(layerSets && { layerSets }),
  });
  const { activeTool } = visualiserState;

  const isToolActive = useCallback(
    (tool: Tool) => activeTool?.value === tool.value,
    [activeTool?.value],
  );
  const onToolComplete = useCallback(() => {
    visualiserDispatch({
      type: "setActiveTool",
      payload: null,
    });
  }, []);

  const visualiserProviderValue: VisualiserContextValue = useMemo(
    () => ({
      is3DEnabled,
      isToolActive,
      onToolComplete,
      visualiserState,
      visualiserDispatch,
    }),
    [
      is3DEnabled,
      isToolActive,
      onToolComplete,
      visualiserState,
      visualiserDispatch,
    ],
  );

  return (
    <VisualiserContext.Provider value={visualiserProviderValue}>
      {children}
    </VisualiserContext.Provider>
  );
};

export default VisualiserProvider;

export const useVisualiser = () => {
  const context = useContext(VisualiserContext);

  if (!context) {
    throw new Error("useVisualiser must be used inside a VisualiserProvider");
  }

  return context;
};

/**
 * In most cases you don't actually want this one. Only use this when you are
 * interacting with the Visualiser in a component where it isn't strictly
 * required -- i.e. your component can still function even if there is no
 * Visualiser present.
 *
 * An example of this is inside useMapInteractionEvents. If there is a
 * Visualiser present, we want to disable interactive map features when a tool
 * is active so that we don't get conflicts between the tool mouse events and
 * the feature interaction events. However if there is no Visualiser at all,
 * this behaviour isn't necessary and we can continue without issue.
 *
 * This hook would not be suitable for a layer, as you wouldn't want a consumer
 * to forget to wrap their layer in the VisualiserProvider.
 */
export const useOptionalVisualiser = () => {
  const context = useContext(VisualiserContext);

  return context;
};
