/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type PredictionStatus =
  (typeof PredictionStatus)[keyof typeof PredictionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PredictionStatus = {
  COMPLETED: "COMPLETED",
  INPROGRESS: "INPROGRESS",
  ERROR: "ERROR",
} as const;
