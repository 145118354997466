import { Field, Text } from "@app/design-system";
import styled from "styled-components";

const StyledEmberDensityRange = styled.div`
  margin: 0.25rem 0 0;
  background-image: linear-gradient(
    to right,
    rgb(255, 235, 236),
    rgb(196, 11, 52)
  );
  width: 100%;
  height: 1rem;
`;

const StyledEmberDensityIndicators = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmberDensityLegend = () => {
  return (
    <Field label="Ember density">
      <StyledEmberDensityRange />
      <StyledEmberDensityIndicators>
        <Text size="caption">0/m&sup2;</Text>
        <Text size="caption">&ge; 2.5/m&sup2;</Text>
      </StyledEmberDensityIndicators>
    </Field>
  );
};

export default EmberDensityLegend;
