import { useCurrentTime } from "@kablamo/kerosene-ui";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import startOfMinute from "date-fns/startOfMinute";
import React, {
  createContext,
  type Dispatch,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useInterval } from "usehooks-ts";

interface SetHoursAction {
  payload: { hours: number };
  type: "setHours";
}

interface ResetAction {
  payload: {
    hours: number;
    start?: number;
  };
  type: "reset";
}

interface ResetStartAction {
  payload: {
    start: number;
  };
  type: "resetStart";
}

interface TimelineState {
  date: Date;
  hours: number;
  start?: number;
  startDate: Date;
}

type TimelineAction = SetHoursAction | ResetAction | ResetStartAction;

type TimelineDispatch = Dispatch<TimelineAction>;

interface TimelineStateInitializerArgs {
  hours: number;
  start?: number;
}

const timelineStateInitializer = ({
  hours,
  start,
}: TimelineStateInitializerArgs): TimelineState => {
  const startDate = start ? new Date(start) : new Date();
  return {
    date: addHours(startDate, hours),
    hours,
    start,
    startDate,
  };
};

export const timelineReducer = (
  state: TimelineState,
  { payload, type }: TimelineAction,
): TimelineState => {
  switch (type) {
    case "setHours": {
      const { hours } = payload;
      const date = addHours(state.startDate, hours);

      return {
        ...state,
        date,
        hours,
      };
    }
    case "reset": {
      const { hours, start } = payload;
      return timelineStateInitializer({
        hours,
        start,
      });
    }
    case "resetStart": {
      const { start } = payload;
      return timelineStateInitializer({
        hours: state.hours,
        start,
      });
    }
  }
};

interface TimelineContextValue {
  timelineDispatch: TimelineDispatch;
  timelineState: TimelineState;
}

const TimelineContext = createContext<TimelineContextValue | undefined>(
  undefined,
);

const getMillisecondsUntilNextMinute = () => {
  const now = new Date();
  const startOfNextMinute = startOfMinute(addMinutes(now, 1));
  return differenceInMilliseconds(startOfNextMinute, now);
};

const ONE_MINUTE = 60 * 1000;

interface TimelineProviderProps {
  children?: React.ReactNode;
  defaultHours?: number;
  defaultStart?: number;
  /**
   * If the timeline is static, the start time will not update to keep pace with
   * the passage of time in realtime. Default values will be retained unless
   * consumers update manually with the dispatcher.
   */
  isStatic?: boolean;
}

const TimelineProvider = ({
  children,
  defaultHours = 0,
  defaultStart,
  isStatic,
}: TimelineProviderProps) => {
  const currentTime = useCurrentTime();
  const [timelineState, timelineDispatch] = useReducer(
    timelineReducer,
    { hours: defaultHours, start: defaultStart ?? currentTime },
    timelineStateInitializer,
  );

  const [millisecondsUntilNextMinute, setMillisecondsUntilNextMinute] =
    useState(getMillisecondsUntilNextMinute);

  useInterval(() => {
    if (isStatic) {
      return;
    }

    const refreshStartTime = () => {
      timelineDispatch({
        type: "resetStart",
        payload: {
          start: startOfMinute(Date.now()).getTime(),
        },
      });
    };

    refreshStartTime();

    if (millisecondsUntilNextMinute !== ONE_MINUTE) {
      setMillisecondsUntilNextMinute(ONE_MINUTE);
    }
  }, millisecondsUntilNextMinute);

  const timelineProviderValue = useMemo(
    () => ({ timelineState, timelineDispatch }),
    [timelineState, timelineDispatch],
  );

  return (
    <TimelineContext.Provider value={timelineProviderValue}>
      {children}
    </TimelineContext.Provider>
  );
};

export default TimelineProvider;

export const useTimeline = () => {
  const context = useContext(TimelineContext);

  if (!context) {
    throw new Error("useTimeline must be used inside a TimelineProvider ");
  }

  return context;
};

export const getHoursOffset = (
  startDate: Date | number,
  relativeTo?: number,
) => {
  if (!relativeTo) {
    return 0;
  }

  const relativeToDate = new Date(relativeTo);
  return differenceInHours(startDate, relativeToDate);
};

export const useRelativeHours = (relativeTo?: number) => {
  const {
    timelineState: { hours, startDate },
  } = useTimeline();

  const hoursOffset = getHoursOffset(startDate, relativeTo);

  return hours + hoursOffset;
};
