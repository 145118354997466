import type { Prediction } from "../../.rest-hooks/types";
import {
  type ManualIncidentPredictionAttributes,
  ManualIncidentPredictionProduct,
  type ManualRedMapPredictionAttributes,
  type PredictionAttributes,
  PredictionProduct,
  type RapidAppreciationPredictionAttributes,
} from "../../.rest-hooks/types/predictions.yml";
import BurnProbabilityLegend from "../components/legends/BurnProbabilityLegend/BurnProbabilityLegend";
import EmberDensityLegend from "../components/legends/EmberDensityLegend/EmberDensityLegend";
import FlameHeightLegend from "../components/legends/FlameHeightLegend/FlameHeightLegend";
import {
  ManualPredictionLegend,
  RedMapLegend,
} from "../components/legends/Legends";
import type { ActivePredictionState } from "../components/ui/PredictionsProvider/PredictionsProvider";

export const PREDICTION_PRODUCTS = Object.values(PredictionProduct);

export const MANUAL_PREDICTION_PRODUCTS: readonly PredictionProduct[] = [
  "best-estimate",
  "break-out-scenarios",
  "enhanced-situational-awareness-products",
  "location-uncertainty-simulation",
  "modelled-worst-case",
  "multiple-day-planning-scenarios",
  "other-prediction",
  "property-impact-frequency",
  "property-impact-from-source",
  "time-to-impact-maps",
] as const;

export const isPredictionProduct = (
  product: string,
): product is PredictionProduct => {
  return PREDICTION_PRODUCTS.includes(product as PredictionProduct);
};

export const isManualUploadPredictionAttributes = (
  attributes: PredictionAttributes | undefined,
): attributes is ManualIncidentPredictionAttributes => {
  if (!attributes) return false;
  return (
    attributes.productType === "manual-upload" &&
    attributes.product in ManualIncidentPredictionProduct
  );
};

export const isRapidAppreciationPredictionAttributes = (
  attributes: PredictionAttributes | undefined,
): attributes is RapidAppreciationPredictionAttributes => {
  if (!attributes) return false;
  return (
    attributes.productType === "automated" &&
    attributes.product === "rapid-appreciation"
  );
};

export const isRedMapPredictionAttributes = (
  attributes: PredictionAttributes | undefined,
): attributes is ManualRedMapPredictionAttributes => {
  if (!attributes) return false;
  return (
    attributes.productType === "manual-upload" &&
    attributes.product === "red-map"
  );
};

export type PredictionVisualisationType =
  | "burnProbability"
  | "flameHeight"
  | "manualUpload"
  | "redMap"
  | "emberDensity";

interface GetPredictionVisualisationTypesParams {
  prediction: Prediction;
  state: ActivePredictionState;
}

export const getPredictionVisualisationTypes = ({
  prediction,
  state,
}: GetPredictionVisualisationTypesParams): PredictionVisualisationType[] => {
  const visualisationTypes: PredictionVisualisationType[] = [];

  if (isRedMapPredictionAttributes(prediction.attributes)) {
    visualisationTypes.push("redMap");
  }

  if (isManualUploadPredictionAttributes(prediction.attributes)) {
    visualisationTypes.push("manualUpload");
  }

  if (
    isRapidAppreciationPredictionAttributes(prediction.attributes) &&
    state.output === "burnProbability"
  ) {
    visualisationTypes.push("burnProbability");
  }

  if (
    isRapidAppreciationPredictionAttributes(prediction.attributes) &&
    state.output === "flameHeight"
  ) {
    visualisationTypes.push("flameHeight");
  }

  if (
    isRapidAppreciationPredictionAttributes(prediction.attributes) &&
    state.isEmberDensityActive
  ) {
    visualisationTypes.push("emberDensity");
  }

  return visualisationTypes;
};

interface PredictionVisualisationLegend {
  label?: string;
  component: React.ComponentType;
}

export const predictionVisualisationLegendMap: Record<
  PredictionVisualisationType,
  PredictionVisualisationLegend
> = {
  burnProbability: {
    component: BurnProbabilityLegend,
    label: "Rapid appreciation",
  },
  flameHeight: {
    component: FlameHeightLegend,
    label: "Rapid appreciation",
  },
  manualUpload: { component: ManualPredictionLegend, label: "Manual Upload" },
  redMap: { component: RedMapLegend, label: "Red Map" },
  emberDensity: {
    component: EmberDensityLegend,
  },
};

export type PredictionAttributesType =
  | "rapid-appreciation"
  | "manual-incident"
  | "manual-red-map";

export const getPredictionAttributesType = (
  predictionAttributes: PredictionAttributes,
): PredictionAttributesType | null => {
  if (isRapidAppreciationPredictionAttributes(predictionAttributes)) {
    return "rapid-appreciation";
  }

  if (isManualUploadPredictionAttributes(predictionAttributes)) {
    return "manual-incident";
  }

  if (isRedMapPredictionAttributes(predictionAttributes)) {
    return "manual-red-map";
  }

  return null;
};
