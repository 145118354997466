import { useIsMinWidth } from "@app/design-system";
import compact from "lodash/compact";
import uniqBy from "lodash/uniqBy";
import type mapboxgl from "mapbox-gl";
import { useEffect } from "react";
import { useOptionalVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import useUnsafeMapContext from "../Map/useUnsafeMapContext";
import type { MapboxMouseEvent } from "../types";
import type { InteractiveLayerMap } from "./MapInteractionsProvider";
import type { FeatureInteractionProperties } from "./types";
import type {
  ActivateInteractionStateParams,
  MapInteractionState,
} from "./useMapInteractionState";

interface FireTopFeatureOnClickIfPresentParams {
  event: MapboxMouseEvent | maplibregl.MapLayerEventType["mousedown"];
  layers: InteractiveLayerMap;
}

/**
 * Triggers the custom `onClick` callback attached to the layer that the topmost
 * feature belongs to, if there is one configured on it. This is useful for
 * grabbing details from a clicked feature to use in external state such as
 * a map rail modal.
 */
const fireTopFeatureOnClickIfPresent = ({
  event,
  layers,
}: FireTopFeatureOnClickIfPresentParams) => {
  const { features } = event;
  const topFeature = features?.[0];
  if (!topFeature) return;
  const layer = layers.get(topFeature.layer.id);
  if (!layer || !layer.onClick) return;
  const properties = layer.getPropertiesFromFeature(topFeature, event);
  if (!properties) return;
  layer.onClick(properties);
};

interface GetActivateInteractionStateParams {
  event: MapboxMouseEvent | maplibregl.MapLayerEventType["mousedown"];
  layers: InteractiveLayerMap;
}

const getActivateInteractionStateParams = ({
  event,
  layers,
}: GetActivateInteractionStateParams): ActivateInteractionStateParams<FeatureInteractionProperties> | null => {
  const { features } = event;

  const allFeatures = uniqBy(
    compact(
      features?.map((feature) => {
        const layer = layers.get(feature.layer.id);

        // This feature belongs to a layer that is not registered to the
        // interactions provider
        if (!layer) return;

        const properties = layer.getPropertiesFromFeature(feature, event);

        // This feature did not return valid properties from the layer's
        // `getPropertiesFromFeature` function
        if (!properties) return;

        return {
          layerId: feature.layer.id,
          properties,
        };
      }) ?? [],
    ),
    (feature) => feature.properties.featureId,
  );

  const [firstFeature] = allFeatures;

  // None of the features under the mouse event were valid interaction targets
  if (!firstFeature) return null;

  return {
    layerId: firstFeature.layerId,
    properties: firstFeature.properties,
    allFeatures,
  };
};

interface UseMapInteractionEventsParams {
  layers: InteractiveLayerMap;
  interactionState: MapInteractionState<FeatureInteractionProperties>;
}

const useMapInteractionEvents = ({
  layers,
  interactionState: mapInteractionState,
}: UseMapInteractionEventsParams) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");
  const { lib, map } = useUnsafeMapContext();

  // If the map interactions are happening in a context where there could be
  // an active map tool, we want to check whether the tool is active before
  // applying our map events in order to prevent conflicting with the tool
  // behaviour.
  const visualiser = useOptionalVisualiser();
  const activeTool = visualiser?.visualiserState.activeTool;

  const { activateHoverState, deactivateHoverState, activateClickState } =
    mapInteractionState;

  useEffect(() => {
    if (!isTabletLandscapeAndAbove || activeTool) return;

    const onMouseMove = (
      event: mapboxgl.MapMouseEvent | maplibregl.MapLayerEventType["mousemove"],
    ) => {
      if (map) {
        map.getCanvas().style.cursor = "pointer";
      }

      const params = getActivateInteractionStateParams({ event, layers });
      if (!params) return;

      activateHoverState(params);
    };

    const onMouseLeave = () => {
      if (map) {
        map.getCanvas().style.cursor = "";
      }

      deactivateHoverState();
    };

    const layerIds = [...layers.keys()];

    if (lib === "mapbox") {
      map?.on("mousemove", layerIds, onMouseMove);
      map?.on("mouseleave", layerIds, onMouseLeave);
    } else {
      map?.on("mousemove", layerIds, onMouseMove);
      map?.on("mouseleave", layerIds, onMouseLeave);
    }

    return () => {
      if (lib === "mapbox") {
        map?.off("mousemove", layerIds, onMouseMove);
        map?.off("mouseleave", layerIds, onMouseLeave);
      } else {
        map?.off("mousemove", layerIds, onMouseMove);
        map?.off("mouseleave", layerIds, onMouseLeave);
      }
    };
  }, [
    activateHoverState,
    activeTool,
    deactivateHoverState,
    isTabletLandscapeAndAbove,
    layers,
    lib,
    map,
  ]);

  useEffect(() => {
    if (activeTool) return;

    const onClick = (
      event: MapboxMouseEvent | maplibregl.MapLayerEventType["click"],
    ) => {
      fireTopFeatureOnClickIfPresent({ event, layers });

      const params = getActivateInteractionStateParams({ event, layers });
      if (!params) return;

      activateClickState(params);
    };

    const layerIds = [...layers.keys()];

    if (lib === "mapbox") {
      map?.on("click", layerIds, onClick);
    } else if (lib === "maplibre") {
      map?.on("click", layerIds, onClick);
    }

    return () => {
      if (lib === "mapbox") {
        map?.off("click", layerIds, onClick);
      } else if (lib === "maplibre") {
        map?.off("click", layerIds, onClick);
      }
    };
  }, [activateClickState, activeTool, layers, lib, map]);
};

export default useMapInteractionEvents;
