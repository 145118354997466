import isEqual from "lodash/isEqual";
import uniqueId from "lodash/uniqueId";
import { useCallback, useReducer, type Reducer } from "react";
import useUpdatingRef from "../../../hooks/useUpdatingRef";
import {
  InteractionStateType,
  type FeatureInteractionProperties,
  type FeatureInteractionState,
  type InteractionFeature,
} from "./types";

type InteractionState<P extends FeatureInteractionProperties> = {
  clicked: FeatureInteractionState<P>;
  hovered: FeatureInteractionState<P>;
};

type ActivateInteractionAction<P extends FeatureInteractionProperties> = {
  payload: {
    allFeatures: InteractionFeature[];
    interactionType: InteractionStateType;
    layerId: string;
    properties: P;
  };
  type: "activateInteraction";
};

type DeactivateInteractionAction = {
  payload: {
    interactionType: InteractionStateType;
  };
  type: "deactivateInteraction";
};

type InteractionAction<P extends FeatureInteractionProperties> =
  | ActivateInteractionAction<P>
  | DeactivateInteractionAction;

type InteractionReducer<P extends FeatureInteractionProperties> = Reducer<
  InteractionState<P>,
  InteractionAction<P>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialInteractionState: InteractionState<any> = {
  clicked: {
    allFeatures: null,
    id: "",
    isActive: false,
    layerId: null,
    properties: null,
  },
  hovered: {
    allFeatures: null,
    id: "",
    isActive: false,
    layerId: null,
    properties: null,
  },
};

const interactionReducer = <P extends FeatureInteractionProperties>(
  state: InteractionState<P>,
  action: InteractionAction<P>,
): InteractionState<P> => {
  switch (action.type) {
    case "deactivateInteraction": {
      switch (action.payload.interactionType) {
        case InteractionStateType.CLICKED: {
          return {
            ...state,
            clicked: {
              ...state.clicked,
              isActive: false,
            },
          };
        }
        case InteractionStateType.HOVERED: {
          if (!state.hovered.isActive) {
            return state;
          }
          return {
            ...state,
            hovered: {
              ...state.hovered,
              isActive: false,
            },
          };
        }
      }
      break;
    }
    case "activateInteraction": {
      switch (action.payload.interactionType) {
        case InteractionStateType.CLICKED: {
          // If the clicked feature is the same as the current active hover
          // feature, we want to activate the clicked state AND deactivate the
          // current hovered state, as the clicked state should supersede
          // hovered.
          if (
            action.payload.properties.featureId ===
            state.hovered.properties?.featureId
          ) {
            return {
              clicked: {
                allFeatures: action.payload.allFeatures,
                id: uniqueId("feature"),
                layerId: action.payload.layerId,
                properties: action.payload.properties,
                isActive: true,
              },
              hovered: {
                ...state.hovered,
                isActive: false,
              },
            };
          }

          // Otherwise we just update the clicked state
          return {
            ...state,
            clicked: {
              allFeatures: action.payload.allFeatures,
              id: state.clicked.isActive
                ? state.clicked.id
                : uniqueId("feature"),
              layerId: action.payload.layerId,
              properties: action.payload.properties,
              isActive: true,
            },
          };
        }
        case InteractionStateType.HOVERED: {
          // If the feature being hovered is the same as the current active
          // "clicked" feature, we don't want to allow the hovered state to
          // show up again.
          if (
            action.payload.properties.featureId ===
              state.clicked.properties?.featureId &&
            state.clicked.isActive
          ) {
            if (state.hovered.isActive) {
              return {
                ...state,
                hovered: {
                  ...state.hovered,
                  properties: action.payload.properties,
                  isActive: false,
                },
              };
            }

            return state;
          }

          if (
            // Hovered states can move around in terms of latitude and longitude
            // even when referring to the same feature -- for example polygon hover
            // state where we want the hover to follow the mouse around. So we
            // make sure to test if they are exactly identical, instead of
            // of also allowing an ID comparison using our custom
            // `arePropertiesEqual` function, in order for a change in
            // coordinates to still fall through and trigger a state update.
            isEqual(action.payload.properties, state.hovered.properties) &&
            state.hovered.isActive
          ) {
            return state;
          }

          return {
            ...state,
            hovered: {
              allFeatures: action.payload.allFeatures,
              id:
                action.payload.properties.featureId ===
                state.hovered.properties?.featureId
                  ? state.hovered.id
                  : uniqueId("feature"),
              layerId: action.payload.layerId,
              properties: action.payload.properties,
              isActive: true,
            },
          };
        }
      }
    }
  }
};

export interface ActivateInteractionStateParams<
  P extends FeatureInteractionProperties,
> {
  allFeatures: InteractionFeature[];
  layerId: string;
  properties: P;
}

type ActivateInteractionStateFn<P extends FeatureInteractionProperties> = (
  params: ActivateInteractionStateParams<P>,
) => void;

export interface MapInteractionState<P extends FeatureInteractionProperties> {
  activateClickState: ActivateInteractionStateFn<P>;
  activateHoverState: ActivateInteractionStateFn<P>;
  clickedState: FeatureInteractionState<P>;
  hoveredState: FeatureInteractionState<P>;
  deactivateClickState: () => void;
  deactivateHoverState: () => void;
}

type InteractionHandler<P extends FeatureInteractionProperties> = (
  properties: P,
) => void;

interface UseMapInteractionStateParams<P extends FeatureInteractionProperties> {
  onClick?: InteractionHandler<P>;
}

/**
 * Provides state for tracking clicked or hovered features, and utility
 * functions to update that state.
 *
 * @param params
 * @param params.onClick - An event handler called when a new feature is clicked.
 * @returns {Object} Map interaction state and utility functions to update it.
 */
const useMapInteractionState = <P extends FeatureInteractionProperties>({
  onClick,
}: UseMapInteractionStateParams<P> = {}): MapInteractionState<P> => {
  const [interactionState, interactionDispatch] = useReducer<
    InteractionReducer<P>
  >(interactionReducer, initialInteractionState);

  const onClickRef = useUpdatingRef(onClick);

  const activateClickState: ActivateInteractionStateFn<P> = useCallback(
    ({ allFeatures, layerId, properties }) => {
      interactionDispatch({
        payload: {
          allFeatures,
          interactionType: InteractionStateType.CLICKED,
          layerId,
          properties,
        },
        type: "activateInteraction",
      });

      onClickRef.current?.(properties);
    },
    [onClickRef],
  );

  const activateHoverState: ActivateInteractionStateFn<P> = useCallback(
    ({ allFeatures, layerId, properties }) => {
      interactionDispatch({
        payload: {
          allFeatures,
          interactionType: InteractionStateType.HOVERED,
          layerId,
          properties,
        },
        type: "activateInteraction",
      });
    },
    [],
  );

  const deactivateClickState = useCallback(() => {
    interactionDispatch({
      payload: {
        interactionType: InteractionStateType.CLICKED,
      },
      type: "deactivateInteraction",
    });
  }, []);

  const deactivateHoverState = useCallback(() => {
    interactionDispatch({
      payload: {
        interactionType: InteractionStateType.HOVERED,
      },
      type: "deactivateInteraction",
    });
  }, []);

  return {
    activateClickState,
    activateHoverState,
    deactivateClickState,
    deactivateHoverState,
    clickedState: interactionState.clicked,
    hoveredState: interactionState.hovered,
  };
};

export default useMapInteractionState;
