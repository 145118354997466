/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Product of a manual prediction for an incident.
 */
export type ManualIncidentPredictionProduct =
  (typeof ManualIncidentPredictionProduct)[keyof typeof ManualIncidentPredictionProduct];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualIncidentPredictionProduct = {
  "best-estimate": "best-estimate",
  "break-out-scenarios": "break-out-scenarios",
  "enhanced-situational-awareness-products":
    "enhanced-situational-awareness-products",
  "location-uncertainty-simulation": "location-uncertainty-simulation",
  "modelled-worst-case": "modelled-worst-case",
  "multiple-day-planning-scenarios": "multiple-day-planning-scenarios",
  "other-prediction": "other-prediction",
  "property-impact-frequency": "property-impact-frequency",
  "property-impact-from-source": "property-impact-from-source",
  "time-to-impact-maps": "time-to-impact-maps",
} as const;
