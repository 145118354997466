import throttle from "lodash/throttle";
import type mapboxgl from "mapbox-gl";
import type maplibregl from "maplibre-gl";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useUnsafeMapContext from "../components/map/Map/useUnsafeMapContext";

const MouseContext = createContext<
  | {
      mouseLocation: LngLatObject;
      setMouseLocation: (location: LngLatObject) => void;
    }
  | undefined
>(undefined);

const getInitialMouseLocation = (
  map: mapboxgl.Map | maplibregl.Map | null,
): LngLatObject => {
  return (
    map?.getCenter() ?? {
      lng: 0,
      lat: 0,
    }
  );
};

const MouseProvider = ({ children }: { children: React.ReactNode }) => {
  const { map } = useUnsafeMapContext();
  const [mouseLocation, setMouseLocation] = useState<LngLatObject>(() =>
    getInitialMouseLocation(map),
  );

  useEffect(() => {
    setMouseLocation(getInitialMouseLocation(map));
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const setMouseLocationThrottled = throttle(
      (event: mapboxgl.MapMouseEvent) => {
        setMouseLocation(event.lngLat.wrap());
      },
      50,
    );

    map.on("mousemove", setMouseLocationThrottled);
    map.on("touchmove", setMouseLocationThrottled);
    map.on("touchstart", setMouseLocationThrottled);

    return () => {
      map.off("mousemove", setMouseLocationThrottled);
      map.off("touchmove", setMouseLocationThrottled);
      map.off("touchstart", setMouseLocationThrottled);
    };
  }, [map, setMouseLocation]);

  const mouseProviderValue = useMemo(
    () => ({ mouseLocation, setMouseLocation }),
    [mouseLocation, setMouseLocation],
  );

  return (
    <MouseContext.Provider value={mouseProviderValue}>
      {children}
    </MouseContext.Provider>
  );
};

export default MouseProvider;

export const useMouseLocation = () => {
  const mouseLocation = useContext(MouseContext);
  if (!mouseLocation) {
    throw new Error("useMouseLocation must be used within a MouseProvider");
  }
  return mouseLocation;
};
