import defaults from "lodash/defaults";
import {
  createContext,
  type Dispatch,
  type Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import type { Prediction } from "../../../../.rest-hooks/types";
import {
  getPredictionVisualisationTypes,
  type PredictionVisualisationType,
} from "../../../config/predictionProduct";

export type PredictionMode = "unified" | "split";

export type PredictionOutput = "burnProbability" | "flameHeight";

export interface GlobalPredictionsState {
  isEmberDensityActive: boolean;
  opacity: number;
  output: PredictionOutput;
}

export interface ActivePredictionState {
  isImpactsActive: boolean;
  isImpactsCountActive: boolean;
  isEmberDensityActive: boolean;
  isStatic: boolean;
  opacity: number;
  output: PredictionOutput;
}

export interface AddPredictionsPayload {
  predictions: Prediction[];
}

export interface AddPredictionsAction {
  payload: AddPredictionsPayload;
  type: "addPredictions";
}

export interface RemovePredictionsPayload {
  predictions: Prediction[];
}

export interface RemovePredictionsAction {
  payload: RemovePredictionsPayload;
  type: "removePredictions";
}

interface ActivatePredictionsPayload {
  predictionIds: string[];
  activeState?: Partial<ActivePredictionState>;
}

export interface ActivatePredictionsAction {
  payload: ActivatePredictionsPayload;
  type: "activatePredictions";
}

interface DeactivatePredictionsPayload {
  predictionIds: string[];
}

export interface DeactivatePredictionsAction {
  payload: DeactivatePredictionsPayload;
  type: "deactivatePredictions";
}

export interface SetPredictionActiveStatePayload {
  activeState: Partial<ActivePredictionState>;
  predictionId: string;
}

export interface SetPredictionActiveStateAction {
  payload: SetPredictionActiveStatePayload;
  type: "setPredictionActiveState";
}

export interface SetPredictionModePayload {
  predictionMode: PredictionMode;
}

export interface SetPredictionModeAction {
  payload: SetPredictionModePayload;
  type: "setPredictionMode";
}

export interface SetGlobalPredictionsStatePayload {
  globalState: Partial<GlobalPredictionsState>;
}

export interface SetGlobalPredictionsStateAction {
  payload: SetGlobalPredictionsStatePayload;
  type: "setGlobalPredictionsState";
}

export type PredictionsAction =
  | AddPredictionsAction
  | RemovePredictionsAction
  | ActivatePredictionsAction
  | DeactivatePredictionsAction
  | SetPredictionActiveStateAction
  | SetPredictionModeAction
  | SetGlobalPredictionsStateAction;

export type PredictionsDispatch = Dispatch<PredictionsAction>;

export interface PredictionsState {
  activePredictions: Map<string, ActivePredictionState>;
  globalState: GlobalPredictionsState;
  predictionMode: PredictionMode;
  predictions: Map<string, Prediction>;
}

type PredictionsReducer = Reducer<PredictionsState, PredictionsAction>;

const DEFAULT_PREDICTION_OPACITY = 0.8;
const DEFAULT_PREDICTION_OUTPUT = "burnProbability";

export const DEFAULT_ACTIVE_PREDICTION_STATE: ActivePredictionState = {
  isImpactsActive: false,
  isImpactsCountActive: false,
  isEmberDensityActive: false,
  isStatic: false,
  opacity: DEFAULT_PREDICTION_OPACITY,
  output: DEFAULT_PREDICTION_OUTPUT,
};

export const DEFAULT_GLOBAL_PREDICTIONS_STATE: GlobalPredictionsState = {
  isEmberDensityActive: false,
  opacity: DEFAULT_PREDICTION_OPACITY,
  output: DEFAULT_PREDICTION_OUTPUT,
};

export const predictionsReducer: PredictionsReducer = (
  state: PredictionsState,
  { payload, type }: PredictionsAction,
): PredictionsState => {
  switch (type) {
    case "addPredictions": {
      const predictions = new Map(
        payload.predictions.map((prediction) => [prediction.id, prediction]),
      );

      return {
        ...state,
        predictions,
      };
    }
    case "removePredictions": {
      const predictions = new Map(
        [...state.predictions].filter(
          ([predictionId]) =>
            !payload.predictions.some(
              (prediction) => predictionId === prediction.id,
            ),
        ),
      );

      return {
        ...state,
        predictions,
      };
    }
    case "activatePredictions": {
      const activePredictions = new Map(state.activePredictions);

      payload.predictionIds.forEach((predictionId) => {
        const prevActiveState = activePredictions.get(predictionId);

        activePredictions.set(
          predictionId,
          defaults<
            Partial<ActivePredictionState>,
            Partial<ActivePredictionState>,
            Partial<ActivePredictionState>,
            Partial<ActivePredictionState>,
            ActivePredictionState
          >(
            {},
            // The new state properties being set by the payload
            { ...payload.activeState },
            // Existing state properties from the previous state
            {
              ...prevActiveState,
            },
            // Any left unassigned that we could inherit from global state
            {
              isEmberDensityActive: state.globalState.isEmberDensityActive,
              opacity: state.globalState.opacity,
              output: state.globalState.output,
            },
            // Default to fill any gaps
            {
              ...DEFAULT_ACTIVE_PREDICTION_STATE,
            },
          ),
        );
      });

      return {
        ...state,
        activePredictions,
      };
    }
    case "deactivatePredictions": {
      const activePredictions = new Map(state.activePredictions);

      payload.predictionIds.forEach((predictionId) =>
        activePredictions.delete(predictionId),
      );

      return {
        ...state,
        activePredictions,
      };
    }
    case "setPredictionActiveState": {
      const { activeState, predictionId } = payload;

      const prevActiveState = state.activePredictions.get(predictionId);

      const activePredictions = new Map(state.activePredictions);
      activePredictions.set(
        predictionId,
        defaults<
          Partial<ActivePredictionState>,
          Partial<ActivePredictionState>,
          Partial<ActivePredictionState>,
          Partial<ActivePredictionState>,
          ActivePredictionState
        >(
          {},
          { ...activeState },
          { ...prevActiveState },
          {
            isEmberDensityActive: state.globalState.isEmberDensityActive,
            opacity: state.globalState.opacity,
            output: state.globalState.output,
          },
          { ...DEFAULT_ACTIVE_PREDICTION_STATE },
        ),
      );

      return {
        ...state,
        activePredictions,
      };
    }
    case "setPredictionMode": {
      const { predictionMode } = payload;

      if (predictionMode === state.predictionMode) {
        return state;
      }

      return {
        ...state,
        predictionMode,
      };
    }
    case "setGlobalPredictionsState": {
      // Update the state for each currently active prediction to match the new
      // global state
      const activePredictions = new Map(
        [...state.activePredictions.entries()].map(
          ([predictionId, activePrediction]) => [
            predictionId,
            {
              ...activePrediction,
              ...(payload.globalState.isEmberDensityActive !== undefined && {
                isEmberDensityActive: payload.globalState.isEmberDensityActive,
              }),
              ...(payload.globalState.opacity !== undefined && {
                opacity: payload.globalState.opacity,
              }),
              ...(payload.globalState.output !== undefined && {
                output: payload.globalState.output,
              }),
            },
          ],
        ),
      );

      return {
        ...state,
        activePredictions,
        globalState: {
          ...state.globalState,
          ...payload.globalState,
        },
      };
    }
  }
};

interface PredictionsContextValue {
  // The state and dispatcher from core reducer
  predictionsState: PredictionsState;
  predictionsDispatch: PredictionsDispatch;
  // Add and remove predictions from state
  addPredictions: (payload: AddPredictionsPayload) => void;
  removePredictions: (payload: RemovePredictionsPayload) => void;
  // Activate or deactivate predictions from being visualised
  activatePredictions: (payload: ActivatePredictionsPayload) => void;
  deactivatePredictions: (payload: DeactivatePredictionsPayload) => void;
  // Update an individual prediction's impact visualisations, opacity, or static
  // state
  setPredictionActiveState: (payload: SetPredictionActiveStatePayload) => void;
  // Setting the predictions mode (unified or split view)
  setPredictionMode: (payload: SetPredictionModePayload) => void;
  // Set global prediction state (global opacity, global output)
  setGlobalPredictionsState: (
    payload: SetGlobalPredictionsStatePayload,
  ) => void;
  // Utility function to determine what types of visualisation are currently
  // active. Useful for showing and hiding prediction legends depending on
  // which visualisations are visible
  getActiveVisualisationTypes: () => PredictionVisualisationType[];
}

const PredictionsContext = createContext<PredictionsContextValue | undefined>(
  undefined,
);

interface PredictionsProviderProps {
  children?: React.ReactNode;
}

const PredictionsProvider = ({ children }: PredictionsProviderProps) => {
  const [predictionsState, predictionsDispatch] =
    useReducer<PredictionsReducer>(predictionsReducer, {
      activePredictions: new Map(),
      globalState: { ...DEFAULT_GLOBAL_PREDICTIONS_STATE },
      predictionMode: "unified",
      predictions: new Map(),
    });

  const { activePredictions, predictions } = predictionsState;

  const addPredictions = useCallback<PredictionsContextValue["addPredictions"]>(
    (payload) => {
      predictionsDispatch({
        payload,
        type: "addPredictions",
      });
    },
    [],
  );

  const removePredictions = useCallback<
    PredictionsContextValue["removePredictions"]
  >((payload) => {
    predictionsDispatch({
      payload,
      type: "removePredictions",
    });
  }, []);

  const activatePredictions = useCallback<
    PredictionsContextValue["activatePredictions"]
  >((payload) => {
    predictionsDispatch({
      payload,
      type: "activatePredictions",
    });
  }, []);

  const deactivatePredictions = useCallback<
    PredictionsContextValue["deactivatePredictions"]
  >((payload) => {
    predictionsDispatch({
      payload,
      type: "deactivatePredictions",
    });
  }, []);

  const setPredictionActiveState = useCallback<
    PredictionsContextValue["setPredictionActiveState"]
  >((payload) => {
    predictionsDispatch({
      payload,
      type: "setPredictionActiveState",
    });
  }, []);

  const setPredictionMode = useCallback<
    PredictionsContextValue["setPredictionMode"]
  >((payload) => {
    predictionsDispatch({
      payload,
      type: "setPredictionMode",
    });
  }, []);

  const setGlobalPredictionsState = useCallback<
    PredictionsContextValue["setGlobalPredictionsState"]
  >((payload) => {
    predictionsDispatch({
      payload,
      type: "setGlobalPredictionsState",
    });
  }, []);

  // Return a list of which prediction visualisation types are currently active.
  // For example Burn Probability, Red Map, or Manual Upload Geometry
  const getActiveVisualisationTypes = useCallback(() => {
    const visualisationTypeMap = new Map<
      PredictionVisualisationType,
      boolean
    >();

    activePredictions.forEach((state, predictionId) => {
      const prediction = predictions.get(predictionId);
      if (!prediction) return;

      const visualisationTypes = getPredictionVisualisationTypes({
        prediction,
        state,
      });
      if (!visualisationTypes) return;

      visualisationTypes.forEach((type) =>
        visualisationTypeMap.set(type, true),
      );
    });

    const activeVisualisationTypes = [...visualisationTypeMap.entries()]
      .filter(([, isActive]) => isActive)
      .map(([visualisationType]) => visualisationType);

    return activeVisualisationTypes;
  }, [activePredictions, predictions]);

  // Check whether any currently active predictions are no longer in the list
  // of loaded predictions. If so, remove them from the active state to help
  // keep that map from getting massive.
  const removedActivePredictions = [...activePredictions.entries()].filter(
    ([predictionId]) => !predictions.has(predictionId),
  );
  if (removedActivePredictions.length) {
    predictionsDispatch({
      payload: {
        predictionIds: removedActivePredictions.map(
          ([predictionId]) => predictionId,
        ),
      },
      type: "deactivatePredictions",
    });
  }

  const predictionsProviderValue = useMemo(
    () => ({
      predictionsState,
      predictionsDispatch,
      addPredictions,
      removePredictions,
      activatePredictions,
      deactivatePredictions,
      setPredictionActiveState,
      setPredictionMode,
      setGlobalPredictionsState,
      getActiveVisualisationTypes,
    }),
    [
      predictionsState,
      predictionsDispatch,
      addPredictions,
      removePredictions,
      activatePredictions,
      deactivatePredictions,
      setPredictionActiveState,
      setPredictionMode,
      setGlobalPredictionsState,
      getActiveVisualisationTypes,
    ],
  );

  return (
    <PredictionsContext.Provider value={predictionsProviderValue}>
      {children}
    </PredictionsContext.Provider>
  );
};

export default PredictionsProvider;

export const usePredictions = () => {
  const context = useContext(PredictionsContext);

  if (!context) {
    throw new Error("usePredictions must be used inside a PredictionsProvider");
  }

  return context;
};

interface UseAddPredictionParams {
  prediction: Prediction | undefined;
}

// Use this hook to load predictions into the provider's state so that they can
// be accessed inside any layer or list components without prop-drilling them
// around the place
export const useAddPrediction = ({ prediction }: UseAddPredictionParams) => {
  const { addPredictions, removePredictions } = usePredictions();

  useEffect(() => {
    if (!prediction) return;
    addPredictions({
      predictions: [prediction],
    });

    return () => {
      removePredictions({ predictions: [prediction] });
    };
  }, [addPredictions, prediction, removePredictions]);
};

interface UseAddPredictionsParams {
  predictions: Prediction[] | undefined;
}

// Use this hook to load predictions into the provider's state so that they can
// be accessed inside any layer or list components without prop-drilling them
// around the place
export const useAddPredictions = ({ predictions }: UseAddPredictionsParams) => {
  const { addPredictions, removePredictions } = usePredictions();

  useEffect(() => {
    if (!predictions) return;

    addPredictions({
      predictions,
    });

    return () => {
      removePredictions({ predictions });
    };
  }, [addPredictions, predictions, removePredictions]);
};

interface UseActivatePredictionParams {
  enabled?: boolean;
  isImpactsActive?: boolean;
  isImpactsCountActive?: boolean;
  isEmberDensityActive?: boolean;
  isStatic?: boolean;
  output?: PredictionOutput;
  prediction: Prediction | undefined;
}

// Use this hook when you have one prediction that you would like to activate
// automatically
export const useActivatePrediction = ({
  enabled = true,
  isImpactsActive,
  isImpactsCountActive,
  isEmberDensityActive,
  isStatic,
  output,
  prediction,
}: UseActivatePredictionParams) => {
  const { activatePredictions, setPredictionActiveState } = usePredictions();
  const predictionId = prediction?.id;

  // Respond to changes in ID or enabled state
  useEffect(() => {
    if (!enabled || !predictionId) return;

    activatePredictions({
      predictionIds: [predictionId],
    });
  }, [activatePredictions, enabled, predictionId]);

  // Respond to changes in isImpactsActive
  useEffect(() => {
    if (!enabled || !predictionId) {
      return;
    }

    setPredictionActiveState({
      activeState: {
        isImpactsActive,
      },
      predictionId,
    });
  }, [enabled, isImpactsActive, predictionId, setPredictionActiveState]);

  // Respond to changes in isImpactsCountActive
  useEffect(() => {
    if (!predictionId || !enabled) {
      return;
    }

    setPredictionActiveState({
      activeState: {
        isImpactsCountActive,
      },
      predictionId,
    });
  }, [enabled, isImpactsCountActive, predictionId, setPredictionActiveState]);

  // Respond to changes in isEmberDensityActive
  useEffect(() => {
    if (!predictionId || !enabled) {
      return;
    }

    setPredictionActiveState({
      activeState: {
        isEmberDensityActive,
      },
      predictionId,
    });
  }, [enabled, isEmberDensityActive, predictionId, setPredictionActiveState]);

  // Respond to changes in isStatic
  useEffect(() => {
    if (!predictionId || !enabled) {
      return;
    }

    setPredictionActiveState({
      activeState: {
        isStatic,
      },
      predictionId,
    });
  }, [enabled, isStatic, predictionId, setPredictionActiveState]);

  // Respond to changes in output
  useEffect(() => {
    if (!predictionId || !enabled) {
      return;
    }

    setPredictionActiveState({
      activeState: {
        output,
      },
      predictionId,
    });
  }, [enabled, output, predictionId, setPredictionActiveState]);
};

interface UseActivatePredictionsParams {
  predictions: Prediction[] | undefined;
  isImpactsActive?: boolean;
  isImpactsCountActive?: boolean;
}

// Use this hook when you have a list of predictions that you would like to
// activate automatically
export const useActivatePredictions = ({
  predictions,
  isImpactsActive,
  isImpactsCountActive,
}: UseActivatePredictionsParams) => {
  const { activatePredictions, deactivatePredictions } = usePredictions();

  useEffect(() => {
    if (!predictions) return;

    const predictionIds = predictions.map((prediction) => prediction.id);

    activatePredictions({
      predictionIds,
      activeState: {
        isImpactsActive,
        isImpactsCountActive,
      },
    });

    return () => {
      deactivatePredictions({ predictionIds });
    };
  }, [
    activatePredictions,
    deactivatePredictions,
    isImpactsActive,
    isImpactsCountActive,
    predictions,
  ]);
};

export const useDefaultPredictionMode = (predictionMode: PredictionMode) => {
  const { setPredictionMode } = usePredictions();

  useEffect(() => {
    setPredictionMode({ predictionMode });
  }, [predictionMode, setPredictionMode]);
};
