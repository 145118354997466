import ColorRangeLegend, {
  type ColorRange,
  type ColorRangeCollection,
} from "../ColorRangeLegend/ColorRangeLegend";

const burnProbabilityRanges: ColorRange[] = [
  { color: "#BACBED", range: [0, 10] },
  { color: "#85A4E0", range: [10, 20] },
  { color: "#717EDB", range: [20, 30] },
  { color: "#4758D0", range: [30, 40] },
  { color: "#4344CF", range: [40, 50] },
  { color: "#4D16DF", range: [50, 60] },
  { color: "#3D11B0", range: [60, 70] },
  { color: "#9235EE", range: [70, 80] },
  { color: "#BE35EE", range: [80, 90] },
  { color: "#D435EE", range: [90, 100] },
];

const ranges: ColorRangeCollection[] = [
  { label: "Burn probability (%)", ranges: burnProbabilityRanges },
];

const BurnProbabilityLegend = () => {
  return <ColorRangeLegend ranges={ranges} />;
};

export default BurnProbabilityLegend;
